<template>
  <div class="min-h-screen">
    <div v-if="cart.length === 0">
      <p class="mt-8 text-xl text-purple-600 font-semibold">
        You don't have anything in your cart yet.
      </p>
      <a
        @click.prevent="toggleCart"
        href="#"
        class="flex font-semibold text-indigo-600 text-sm mt-10"
      >
        <svg
          class="fill-current mr-2 text-indigo-600 w-4"
          viewBox="0 0 448 512"
        >
          <path
            d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
          />
        </svg>
        Continue Shopping
      </a>
    </div>
    <div v-else>
      <div class="flex flex-wrap shadow-md my-10">
        <div class="w-full lg:w-3/4 bg-white px-10 py-10">
          <div class="flex justify-between border-b border-purple-300 pb-8">
            <h1 class="font-semibold text-2xl text-purple-600">
              Shopping Cart
            </h1>
            <h2 class="font-semibold text-2xl text-purple-500">
              {{ cart.length }} {{ cart.length === 1 ? "Item" : "Items" }}
            </h2>
          </div>
          <div class="flex mt-10 mb-5">
            <div class="flex items-center justify-between w-full">
              <h3 class="font-semibold text-purple-500 text-xs uppercase w-2/5">
                Details
              </h3>

              <h3
                class="
                  font-semibold
                  text-center text-purple-500 text-xs
                  uppercase
                  text-center
                "
              >
                Price
              </h3>
            </div>
          </div>
          <div
            v-for="(product, i) in cart"
            :key="product.id"
            class="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5"
          >
            <div class="flex w-2/5">
              <!-- product -->
              <div class="w-20">
                <img class="h-24" :src="product.img" alt="" />
              </div>
              <div class="flex flex-col justify-between ml-4 flex-grow">
                <span class="font-bold text-sm text-purple-600">{{
                  product.name
                }}</span>
                <a
                  @click.prevent="
                    $emit('removeItem', { index: i, price: product.price })
                  "
                  href="#"
                  class="font-semibold hover:text-red-500 text-gray-500 text-xs"
                  >Remove</a
                >
              </div>
            </div>
            <div class="flex flex-row-reverse w-3/5">
              <span class="text-center font-semibold text-sm text-purple-600"
                >{{ product.price }} $PZA</span
              >
            </div>
          </div>
          <div class="flex justify-between items-center">
            <a
              @click.prevent="toggleCart"
              href="#"
              class="flex font-semibold text-indigo-600 text-sm mt-10"
            >
              <svg
                class="fill-current mr-2 text-indigo-600 w-4"
                viewBox="0 0 448 512"
              >
                <path
                  d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                />
              </svg>
              Continue Shopping
            </a>
            <a
              @click.prevent="
                $emit('emptyCart');
                toggleCart();
              "
              href="#"
              class="flex font-semibold text-red-600 text-sm mt-10"
            >
              Remove everything from cart
            </a>
          </div>
        </div>

        <div
          id="summary"
          class="w-full lg:w-1/4 px-8 py-10 bg-purple-600 text-white"
        >
          <h1 class="font-semibold text-2xl border-b pb-8">Order Summary</h1>
          <div class="flex justify-between mt-10 mb-5">
            <span class="font-semibold text-sm uppercase"
              >{{ cart.length }}
              {{ cart.length === 1 ? "Item" : "Items" }}
            </span>
            <span class="font-semibold text-sm"
              >{{ cartTotal }} $PZA total</span
            >
          </div>

          <div class="py-10">
            <label
              for="promo"
              class="font-semibold inline-block mb-3 text-sm uppercase"
              >Discord ID</label
            >
            {{ discord }}
            <p class="text-sm mb-3">
              This can be used to contact you and it is attached the to presale
              spot
            </p>
            <input
              type="text"
              v-model="discord"
              placeholder="Enter your discord id"
              class="p-2 text-sm text-black w-full rounded focus:outline-none"
            />
          </div>
          <div class="pb-10">
            <label class="font-semibold inline-block mb-3 text-sm uppercase"
              >Ethereum wallet</label
            >
            <p class="text-sm mb-3">
              Default to the connected account if none provided
            </p>
            <input
              type="text"
              v-model="receiver"
              placeholder="Enter your wallet"
              class="p-2 text-sm text-black w-full rounded focus:outline-none"
            />
          </div>
          <!-- Disclaimer -->
          <p class="mt-4 text-white text-xs">
            By selecting this box, I affirm that these presale spots are NOT
            financial advice whatsoever. I also understand the projects
            represented in these presale spots are not guaranteed to be
            successful or mint out. I acknowledge I need to do my own research
            before minting the project, and will not hold the Average Punks or
            anyone in the community accountable for my decision to redeem the
            presale spot(s) held in this cart. I further understand there will
            be no reimbursements of PZA for my purchases.
          </p>
          <input type="checkbox" v-model="agreedDisclaimer" />
          <div class="border-t mt-8">
            <div
              class="flex font-semibold justify-between py-6 text-sm uppercase"
            >
              <span>Total cost</span>
              <span>{{ cartTotal }} $PZA</span>
            </div>
            <button
              :disabled="!agreedDisclaimer"
              @click="finalizeOrder"
              :class="
                agreedDisclaimer
                  ? 'bg-green-500 hover:bg-green-600'
                  : 'cursor-default'
              "
              class="
                rounded
                font-semibold
                py-3
                text-sm text-white
                uppercase
                w-full
              "
            >
              Finalize order
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import messages from "@/mixinMessage.js";
const SHOP_URL = "https://averagepunks.app";

export default {
  name: "Cart",
  mixins: [messages],
  props: {
    cart: {
      type: Array,
      default: null,
    },

    wallet: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    discord: "",
    agreedDisclaimer: false,
    receiver: "",
  }),

  watch: {
    wallet: {
      immediate: true,
      handler(v) {
        this.receiver = v;
      },
    },
  },

  computed: {
    cartTotal() {
      return this.cart.reduce((t, e) => t + e.price, 0);
    },
  },

  methods: {
    async finalizeOrder() {
      const web3 = window.web3Shop;
      if (!this.discord) {
        return this.showErrorMessage("Please fill in your discord");
      }
      if (!web3.utils.isAddress(this.receiver)) {
        return this.showErrorMessage("Invalid address");
      }
      this.msg =
        "Buy this item for no gas, you are only paying in PIZZA tokens and signing with this address " +
        this.metamaskAddress;
      try {
        this.sig = await web3.eth.personal.sign(this.msg, this.wallet);
        const productIds = this.cart.map((e) => e.id);
        console.log({ productIds: productIds });
        // let wallet =  web3.utils.isAddress(product.wallet) ? product.wallet : this.metamaskAddress;
        await axios.post(`${SHOP_URL}/buy`, {
          productIds,
          buyer: this.receiver,
          discord: this.discord,
          signature: this.sig,
          wallet: this.wallet,
          message: this.msg,
        });
        this.$emit("finalizeOrder");
        this.$emit("emptyCart");
      } catch (error) {
        this.showErrorMessage(error);
      }
    },

    toggleCart() {
      this.$emit("toggle");
    },
  },
};
</script>
        