<template>
	<main-dropdown>
		<template slot-scope="context">
			<a
				@click="context.toggleOpen"
				class="text-xs md:text-base hover:bg-purple-600 hover:text-white border bg-white px-4 border-purple-300 py-2 text-purple-600 uppercase font-semibold rounded cursor-pointer"
			>
				Filter options
			</a>
			<transition
				enter-active-class="transition-all duration-100 ease-out"
				leave-active-class="transition-all duration-100 ease-in"
				enter-class="opacity-0 scale-75"
				enter-to-class="opacity-100 scale-100"
				leave-class="opacity-100 scale-100"
				leave-to-class="opacity-0 scale-75"
			>
				<div
					v-if="context.open"
					class="origin-top-right absolute right-0 mt-2 w-64 bg-white border overflow-hidden rounded-lg shadow-md"
					style="z-index: 100"
				>
					<ul>
						<li class="hover:bg-gray-100">
							<p
								@click="sortPrice('none')"
								:class="sort == 'none' ? 'bg-gray-100' : ''"
								class="font-semibold block px-4 py-3 cursor-pointer text-purple-600 text-sm"
							>
								Newest
							</p>
						</li>

						<li class="hover:bg-gray-100">
							<p
								@click="sortPrice('low')"
								:class="sort == 'low' ? 'bg-gray-100' : ''"
								class="font-semibold block px-4 py-3 cursor-pointer text-purple-600 text-sm"
							>
								Prices low to high
							</p>
						</li>
						<li class="hover:bg-gray-100">
							<p
								@click="sortPrice('high')"
								:class="sort == 'high' ? 'bg-gray-100' : ''"
								class="font-semibold block px-4 py-3 cursor-pointer text-purple-600 text-sm"
							>
								Prices high to low
							</p>
						</li>
						<li @click="include_past = !include_past" class="hover:bg-gray-100 block px-4 py-3 cursor-pointer">
							<input
								v-model="include_past"
								class="bg-purple-50 border-purple-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
								type="checkbox"
							/>
							<label
								@click="include_past = !include_past"
								class="text-sm ml-3 text-purple-600 font-semibold cursor-pointer"
								for="flowbite"
								>Include past items</label
							>
						</li>
					</ul>
				</div>
			</transition>
		</template>
	</main-dropdown>
</template>

<script>
import MainDropdown from '@/components/MainDropdown';
export default {
	name: 'button-with-dropdown',
	props: ['sort'],

	data() {
		return {
			include_past: true,
		};
	},
	components: { MainDropdown },
	methods: {
		sortPrice(val) {
			this.$emit('sortPrice', val);
		},
	},

	watch: {
		include_past() {
			this.$emit('include-past', this.include_past);
		},
	},
};
</script>
