<template>
  <vue-tailwind-modal
    :showing="open"
    @close="
      $emit('close');
      rating = 0;
    "
    :showClose="true"
    :backgroundClose="true"
  >
    <div class="flex-col items-center justify-center w-full">
      <p class="text-purple-600 font-semibold">Rate {{ rating }} out of 5</p>
      <star-rating
        @rating-selected="setRating"
        class="my-4"
        v-bind:increment="0.5"
        v-bind:max-rating="5"
        :star-size="32"
        :show-rating="false"
        :rating="rating"
      />
      <p class="text-purple-600 text-xs">Minimum rating 0.5</p>
      <button
        @click="rateProduct"
        class="
          mt-4
          hover:bg-purple-600 hover:text-white
          border
          bg-white
          px-4
          border-purple-300
          py-2
          text-purple-600
          uppercase
          font-semibold
          rounded
          cursor-pointer
        "
      >
        Rate
      </button>
    </div>
  </vue-tailwind-modal>
</template>

<script>
import StarRating from "vue-star-rating";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "RatingModal",
  components: {
    StarRating,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    previousRating: {
      type: Number,
      default: 0,
    },
    productId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    previousRating: {
      immediate: true,
      handler(val) {
        this.rating = val;
      },
    },
  },
  computed: {
    ...mapGetters({
      metamaskAddress: "connection/metamaskAddress",
    }),
  },

  methods: {
    setRating: function (rating) {
      this.rating = rating;
    },

    async rateProduct() {
      let web3 = window.web3Shop;
      let msg = `Rating product ${this.productId}`;
      let sig = await web3.eth.personal.sign(msg, this.metamaskAddress);

      let {
        data: { newRating },
      } = await axios.post("https://averagepunks.app/rateProduct", {
        rating: this.rating * 2,
        signature: sig,
        message: msg,
        wallet: this.metamaskAddress,
        productId: this.productId,
      });
      this.$emit("newRating", newRating[0].avg / 2);
    },
  },
  data: () => {
    return {
      rating: 0.5,
    };
  },
};
</script>