<template>
  <vue-tailwind-modal
    :showing="open.open"
    @close="$emit('close')"
    :showClose="true"
    :backgroundClose="true"
  >
    <p class="text-purple-600 mb-2">
      {{
        open.type === "deposit"
          ? "Make a deposit from your onchain balance into the marketplace"
          : "Withdraw from the marketplace balance to onchain $PZA"
      }}
    </p>
    <input
      type="number"
      v-model="amount"
      class="border w-full p-2 max-w-xs outline-none"
    />
    <div class="flex items-center w-full mt-4">
      <svg
        v-if="fetching"
        class="w-12 h-12"
        version="1.1"
        id="L4"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
      >
        <circle fill="#4f329e" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#4f329e" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#4f329e" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
      <button
        v-else
        @click="open.type === 'deposit' ? deposit() : withdraw()"
        class="
          hover:bg-purple-600 hover:text-white
          border
          bg-white
          px-4
          border-purple-300
          py-2
          text-purple-600
          uppercase
          font-semibold
          rounded
          cursor-pointer
          focus:outline-none
        "
      >
        {{ open.type === "deposit" ? "Deposit" : "Withdraw" }}
      </button>
    </div>
  </vue-tailwind-modal>
</template>


<script>
import Web3 from "web3";
import { ADDRESS, ABI } from "../util/constants/pizza/contract.js";
import Web3ModalMixin from "../mixinWeb3";
import messages from "@/mixinMessage";
// import { serializeError } from "eth-rpc-errors";
import axios from "axios";

const SHOP_URL = "https://averagepunks.app";

export default {
  name: "OnChainModal",
  mixins: [Web3ModalMixin, messages],
  props: {
    open: {
      type: Object,
      required: true,
    },
    wallet: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    fetching: {
      type: Boolean,
      required: true,
    },
  },
  async mounted() {
    const { ethereum } = window;
    if (!ethereum) {
      return;
    }
    window.web3 = new Web3(ethereum);
    const web3 = window.web3;
    this.web3 = web3;
    this.contract = new web3.eth.Contract(ABI, ADDRESS);
  },
  data: () => {
    return {
      amount: 1,
      contract: null,
      web3: null,
    };
  },
  methods: {
    async deposit() {
      this.$emit("fetch", true);
      try {
        if (this.amount === undefined) {
          this.showErrorMessage("amount undefined");
        }
        if (this.amount < 1) {
          this.showErrorMessage("amount too low");
        }
        let f = this.contract.methods.deposit(this.amount);
        await f.estimateGas({ from: this.wallet });
        await f.send({ from: this.wallet });

        // listen to deposit event on backend

        this.showSuccess();
        this.$emit("deposit", this.amount);
      } catch (error) {
        this.$emit("fetch", false);
        this.loading = false;
        return this.showErrorMessage(error);
      }
    },
    async withdraw() {
      this.$emit("fetch", true);
      if (this.amount === undefined) {
        this.showErrorMessage("amount undefined");
      }
      if (this.amount < 1) {
        this.showErrorMessage("amount too low");
      }
      if (this.balance < this.amount) {
        this.showErrorMessage("balance too low");
      }
      try {
        const msg = `Withdraw ${this.amount} $PZA to your onchain wallet ${this.wallet}. This will disable the ability to buy on the marketplace for approximately 5 minutes or until the transaction gets mined.`;
        const sig = await this.web3.eth.personal.sign(msg, this.wallet);

        const res = await axios.post(`${SHOP_URL}/withdraw`, {
          wallet: this.wallet,
          amount: this.amount,
          signature: sig,
          message: msg,
        });
        // console.log({data: res.data})
        let f = this.contract.methods.withdraw(
          this.amount,
          res.data.nonce,
          res.data.blockHeight,
          res.data.signature
        );
        await f.estimateGas({ from: this.wallet });

        await f.send({ from: this.wallet });

        // listen to withdraw event on backend
        this.showSuccess();
        this.$emit("withdraw", this.amount, res.data.blockHeight);
      } catch (error) {
        this.$emit("fetch", false);
        this.loading = false;
        return this.showErrorMessage(error);
      }
    },
  },
};
</script>