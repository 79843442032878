<template>
  <vue-tailwind-modal
    :showing="open"
    @close="$emit('close')"
    :showClose="true"
    :backgroundClose="true"
  >
    <p class="text-sm text-purple-600">
      Address to gift
      <br />
      <span class="text-purple-400"
        >no ens domain - must be an AVP or MVP holder</span
      >
    </p>
    <input
      v-model="giveTo"
      type="text"
      class="border my-2 p-2 rounded text-black w-full"
    />
    <p class="text-purple-600 text-sm">Amount to transfer</p>
    <input
      v-model="amountToGive"
      type="number"
      class="outline-none border w-full my-2 p-2 rounded text-black max-w-xs"
      min="1"
    />
    <br />
    <button
      @click="gift"
      class="
        mt-2
        hover:bg-purple-600 hover:text-white
        border
        bg-white
        px-4
        border-purple-300
        py-2
        text-purple-600
        uppercase
        font-semibold
        rounded
        cursor-pointer
        focus:outline-none
      "
    >
      Transfer
    </button>
  </vue-tailwind-modal>
</template>


<script>
import axios from "axios";
import Web3 from "web3";
import messages from "@/mixinMessage";
import { mapGetters } from "vuex";

const SHOP_URL = "https://averagepunks.app";

export default {
  name: "TransferModal",
  mixins: [messages],

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      web3Shop: null,
      sig: "",
      msg: "",
      giveTo: "",
      amountToGive: 1,
    };
  },
  computed: {
    ...mapGetters({
      metamaskAddress: "connection/metamaskAddress",
    }),
  },
  methods: {
    async gift() {
      if (!this.giveTo || this.amountToGive <= 0)
        return this.showErrorMessage("missing input");

      if (window.ethereum) {
        this.web3Shop = this.web3Shop
          ? this.web3Shop
          : new Web3(window.ethereum);
      }
      const web3 = this.web3Shop;
      let receiver = this.giveTo;
      if (receiver.includes(".eth")) {
        // receiver = await web3.eth.ens.getAddress(receiver);
        return this.showErrorMessage("Please provide the hexadecimal address");
      }
      try {
        this.msg =
          "You are giving " + this.amountToGive + " $PZA to " + receiver;
        // console.log({ msg: this.msg });
        this.sig = await web3.eth.personal.sign(this.msg, this.metamaskAddress);
        await axios.post(`${SHOP_URL}/transferPizzas`, {
          receiver: receiver,
          amount: this.amountToGive,
          wallet: this.metamaskAddress,
          message: this.msg,
          signature: this.sig,
        });
        // console.log("ret ", ret);
        this.giveTo = "";
        this.amountToGive = 1;
        this.$emit("gift");
        this.showSuccess(this.amountToGive + " $PZA sent");
      } catch (error) {
        this.showErrorMessage(error);
      }
    },
  },
};
</script>