<template>
  <div
    :class="product.sold ? 'opacity-50' : ''"
    class="rounded-lg shadow hover:shadow-lg bg-white"
  >
    <div class="relative">
      <img
        :src="product.img"
        class="rounded-t-lg h-auto min-h-72 max-h-72 w-full object-cover"
        style="min-height: 288px; max-height: 288px"
      />
    </div>
    <div class="p-4">
      <div class="flex flex-row items-center">
        <div class="flex-1 text-left">
          <div class="flex flex-col">
            <p class="text-purple-600 no-underline text-md font-bold">
              {{ product.name }}
            </p>
            <p class="text-xs text-purple-300 font-bold">
              {{ product.quantity }} left
              <span v-if="product.total_supply">
                out of {{ product.total_supply }}</span
              >
            </p>
          </div>
        </div>
        <div class="text-right">
          <p class="flex flex-col -mt-3">
            <span class="font-bold text-purple-600 text-sm"
              >{{ product.price }} $PZA</span
            >
          </p>
        </div>
      </div>
      <div :class="product.ratingCount ? '' : 'invisible'">
        <p class="text-purple-600 text-xs mt-2">
          {{ voted || product.numVotes }}
          {{ voted == 1 || product.numVotes == 1 ? "rating" : "ratings" }}
        </p>
        <div class="flex items-center justify-between items-center">
          <star-rating
            :rating="productRating"
            :increment="0.5"
            :read-only="true"
            :star-size="16"
            :show-rating="false"
          />
          <button
            v-if="!product.sold"
            :disabled="!canRate"
            @click="rateProduct(product)"
            :class="
              canRate && !product.sold
                ? 'hover:bg-purple-600 hover:text-white cursor-pointer'
                : 'cursor-default'
            "
            class="
              text-purple-600 text-xs
              uppercase
              px-2
              py-1
              rounded-lg
              border border-purple-600
            "
          >
            rate
          </button>
        </div>
      </div>
      <div
        class="
          text-sm text-purple-600
          mb-2
          max-h-12
          min-h-12
          overflow-y-scroll
          mt-8
        "
      >
        {{ product.description }}
      </div>
      <div
        class="
          flex flex-row
          text-purple-600
          uppercase
          text-xs
          my-4
          w-full
          justify-between
          items-center
        "
        style="max-width: 190px"
      >
        <twitter-icon
          :link="
            product.links && product.links.twitter ? product.links.twitter : '#'
          "
        />
        <discord-icon
          :link="
            product.links && product.links.discord ? product.links.discord : '#'
          "
        />
        <website-icon
          :link="
            product.links && product.links.website ? product.links.website : '#'
          "
        />
        <instagram-icon
          :link="
            product.links && product.links.instagram
              ? product.links.instagram
              : '#'
          "
        />
      </div>

      <button
        v-show="!hideButton"
        :disabled="!canBuy"
        @click="buy"
        :class="
          canBuy && !product.sold
            ? 'hover:bg-purple-600 hover:text-white cursor-pointer'
            : 'cursor-default'
        "
        class="
          inline-block
          rounded-lg
          font-bold
          uppercase
          transition-colors
          duration-300
          ease-in-out
          levitate
          mt-4
          w-full
          items-center
          bg-white
          text-center text-purple-600
          border border-purple-300
        "
      >
        <span class="inline-block py-3 ml-4 mr-4">ADD TO CART</span>
      </button>
    </div>
    <rating-modal
      v-if="giveRating"
      :productId="product.id"
      :previousRating="rating"
      :open="giveRating"
      @close="giveRating = false"
      @newRating="updateRating"
    />
  </div>
</template>


<script>
import StarRating from "vue-star-rating";
import RatingModal from "./RatingModal.vue";
import DiscordIcon from "./DiscordIcon.vue";
import InstagramIcon from "./InstagramIcon.vue";
import TwitterIcon from "./TwitterIcon.vue";
import WebsiteIcon from "./WebsiteIcon.vue";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Product",
  components: {
    StarRating,
    RatingModal,
    DiscordIcon,
    InstagramIcon,
    TwitterIcon,
    WebsiteIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    canRate: {
      type: Boolean,
      required: true,
    },
    canBuy: {
      type: Boolean,
      required: true,
    },
    hideButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => {
    return {
      giveRating: false,
      rating: 0,
      voted: false,
      firstVote: 0,
    };
  },
  computed: {
    productRating() {
      if (this.rating) {
        return this.rating;
      }
      return this.product.rating ? Math.floor(this.product.rating) / 2 : 0;
    },

    ...mapGetters({
      metamaskAddress: "connection/metamaskAddress",
    }),
  },
  methods: {
    buy() {
      if (this.product.sold) return;
      this.$emit("buy", this.product);
    },

    getRating() {
      return axios
        .get("https://averagepunks.app/productRating", {
          params: {
            productId: this.product.id,
            wallet: this.metamaskAddress,
          },
        })
        .then(({ data }) => {
          this.rating = data.rating / 2 || 0;
          if (!this.rating) this.firstVote = 1;
          // console.log({ rating: this.rating, firstVote: this.firstVote });
        });
    },

    rateProduct(product) {
      if (!product.sold) {
        this.giveRating = true;
        this.getRating();
      }
    },

    updateRating(newRating) {
      this.rating = newRating;
      this.voted = +this.product.numVotes + +this.firstVote;
      this.giveRating = false;
    },
  },
};
</script>